
/* 
* swiper 
*/
:root {
    --swiper-navigation-size: 24px!important;;

}
.swiper{
    z-index: 0!important;
}
.mySwiper{
    /* width:100%; */
    /* height:100vh; */
}
.swiper-slide{
    height:100vh!important;
}
/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
    .swiper-slide {
      /* The hack for Safari */
      height: -webkit-fill-available!important;
    }
}
.webStyle{
    background-image: url('../img/bg-web.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}
.homeStyle{
    background-image: url('../img/bg-home.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover ;
}

.swiper-button-prev,
.swiper-button-next{
  color: #fff!important;
}

