/* HOME */
.home{
  /* height:100vh; */
  /* width: 100%; */
  /* height: 100vh;
  background: url('../img/bg-home.jpg') center bottom / cover no-repeat fixed; */
}
.home_container{
  width: 60%;
  margin: 0 auto;
  padding-top: 15vh;
  text-align: center;
}
.home_container .sub_title{
  width: 220px;
  display: block;
  margin: 0 auto 40px;
}
.home_container .main_title{
  width: 500px;
  display: block;
  margin: 0 auto 30px;
}
.home_container p{
  font-size: 0.9rem;
  text-align: center!important;
  color: #444;
  line-height: 1.9em;
  text-shadow: 
      #fff 2px 0px 2px, #fff -2px 0px 2px,
      #fff 0px -2px 2px, #fff -2px 0px 2px,
      #fff 2px 2px 2px, #fff -2px 2px 2px,
      #fff 2px -2px 2px, #fff -2px -2px 2px,
      #fff 1px 2px 2px, #fff -1px 2px 2px,
      #fff 1px -2px 2px, #fff -1px -2px 2px,
      #fff 2px 1px 2px, #fff -2px 1px 2px,
      #fff 2px -1px 2px, #fff -2px -1px 2px,
      #fff 1px 1px 2px, #fff -1px 1px 2px,
      #fff 1px -1px 2px, #fff -1px -1px 2px;
}


/* 
* Tree
*/
.tree {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
}
.size_xs{
  width: 20vw;
}
.size_s{
  width: 35vw;
}
.size_m{
  width: 45vw;
}
.size_xl{
  width: 65vw;
}
/*
* Bubble ふわふわ効果
*/
@keyframes fuwafuwa {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
.anime_fuwafuwa {
  animation: 3s fuwafuwa infinite;
}

@keyframes wobbling_x {
	0% {
		margin-left: 8px;
	}

	100% {
		margin-left: 0px;
	}
}

@keyframes wobbling_y {
	0% {
		margin-top: 0px;
	}

	100% {
		margin-top: 8px;
	}
}

.anime_random{
  animation: wobbling_x 1.5s ease-in-out infinite alternate,
		wobbling_y 3s ease-in-out infinite alternate;
}
@keyframes anim_sc {
  100% {
    transform: scale(1.1,1.1);
  }
}
.anime_zoom{
  animation-name: anim_sc;
  transform: scale(0.9,0.9);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3s;
}


/*
* FadeIn
*/
.fadeIn{
  animation-name:fadeInAnime;
  animation-duration:3s;
  animation-fill-mode:forwards;
  opacity:0;
}
  
@keyframes fadeInAnime{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bubble {
  position: fixed;
  width: 14rem;
  height: 14rem;
  background-image: url('../img/bubble-01.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content:center
}
.bubble span {
  font-size: 11px;
  line-height: 1.5rem;
  display: block;
  padding: 0 15px;
  text-align: center;
}
.msg_0 {
  background-image: url('../img/bubble-01.png');
  top: 3%;
  left: 70%;
}
.msg_1 {
  background-image: url('../img/bubble-02.png');
  top: 5%;
  left: 45%;
}
.msg_2 {
  background-image: url('../img/bubble-03.png');
  top: 34%;
  left: 55%;
  width: 11rem;
  height: 11rem;
}
.msg_3 {
  background-image: url('../img/bubble-04.png');
  top: 15%;
  left: 24%;
  width: 11rem;
  height: 11rem;
}
.msg_4 {
  background-image: url('../img/bubble-05.png');
  top: 45%;
  left: 30%;
}
.msg_5 {
  top: 35%;
  left: 5%;
}
.msg_6 {
  background-image: url('../img/bubble-06.png');
  top: 42%;
  left: 75%;
}
.msg_7 {
  background-image: url('../img/bubble-07.png');
  top: 70%;
  left: 12%;
  width: 11rem;
  height: 11rem;
}
.msg_8 {
  background-image: url('../img/bubble-08.png');
  top: 60%;
  left: 48%;
}
.msg_9 {
  background-image: url('../img/bubble-09.png');
  top: 70%;
  left: 70%;
  width: 11rem;
  height: 11rem;
}
.open_btn{
  position: fixed;
  top: 5%;
  left: 3%;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 9999px;
  padding: 10px 30px;
  transition: 0.5s;
  box-shadow: 2px 2px 4px 2px rgba(45, 42, 42, 0.5), inset 0px 0px 2px 2px rgba(65, 62, 62, 0.5);
}
.open_btn:hover{
  cursor: pointer;
  opacity: 0.8;
}
.open_btn.mirai {
  background-color: #0065A6;
}
.open_btn.ima {
  background-color: #41A237;
}
/*-----------------
* Nav
------------------*/
.nav{
  width: 90px;
  text-align: center;
  position: fixed;
  top:5%;
  right: 3%;
  z-index: 10;
}
.nav h1{
  margin-bottom: 10px;
}
.link_ima{
  background-color:#41A237;
  height: 90px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: #fff;
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 2px 2px 4px 2px rgba(45, 42, 42, 0.5), inset 0px 0px 2px 2px rgba(65, 62, 62, 0.5);
}
.link_mirai{
  background-color: #0065A6;
  height: 90px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  color: #fff;
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 2px 2px 4px 2px rgba(45, 42, 42, 0.5), inset 0px 0px 2px 2px rgba(65, 62, 62, 0.5);
}
.link_ima span,
.link_mirai span{
  font-size: 1rem;
  color: #fff;
}
.link_ima:hover,
.link_mirai:hover{
  opacity: 0.9;
}
/*-----------------
* modal
------------------*/
.modal{
  width: 800px;
  text-align: center;
  background-color: #fff;
  padding: 50px 50px;
  position: relative;
}
.modal h2{
  font-size: 1.8rem;
}
.modal h3{
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  padding: 10px 0;
  margin: 15px 0;
}
.modal textarea{
  border-bottom: 1px solid #333;
  width: 100%;
}
.modal textarea::placeholder {
  color: #ccc;
}
.modal button{
  display: block;
  margin: 30px auto 0;
}
.modal button:hover{
  cursor: pointer;
}
.modal .disableBtn{
  font-size: 1.4rem;
  background-color: #aaa;
  border: 2px solid #999;
  color: #fff;
  border-radius: 9999px;
  padding: 10px 50px;
}
.modal .submitBtn{
  font-size: 1.4rem;
  background-color: #d20010;
  border: 2px solid #9f000c;
  color: #fff;
  border-radius: 9999px;
  padding: 10px 50px;
}
.modal .notes{
  margin-top: 30px;
  text-align: left;
  font-size: 10px;
  color: #777;
}
.modal .thanks h2{
  color: #9f000c;
  margin: 40px 0 20px;
}
.modal .thanks p{
  margin-bottom: 30px;
}
.modal .arrowDown {
  display: inline-block;
  vertical-align: middle;
  color: #999;
  line-height: 1;
  position: relative;
  width: 0.1em;
  height: 2.5em;
  background: currentColor;
}
.modal .arrowDown::before {
  content: '';
  width: 1.65em;
  height: 1.65em;
  border: 0.1em solid currentColor;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  position: absolute;
  left: 50%;
  bottom: -0.05em;
  box-sizing: border-box;
}
/* 閉じるボタン */
.modal .batsu {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top:12px;
  right: 12px;
}
.modal .batsu:hover{
  cursor: pointer;
}
.modal .batsu::before, .batsu::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px; /* 棒の幅（太さ） */
  height: 25px; /* 棒の高さ */
  background: #999;
}
.modal .batsu::before {
  transform: translate(-50%,-50%) rotate(45deg);
}
.modal .batsu::after {
  transform: translate(-50%,-50%) rotate(-45deg);
}
/*-----------------
* SP対応
------------------*/
@media (max-width: 767px) { 
  /* HOME */
  .home_container{
    width: 85vw;
    margin: 0 auto;
    padding-top: 15vh;
    /* padding-top: 0; */
  }
  .home_container .sub_title{
    width: 35vw;
    display: block;
    margin: 0 auto 2vw;

  }
  .home_container .main_title{
    width: 60vw;
    display: block;
    margin: 0 auto 5vw;
  }
  .home_container p{
    font-size: 0.5rem;
    line-height: 1.1rem;
  }
  .home_container p br{
    display: none;
  }
  /*-----------------
  * Nav
  ------------------*/
  .nav h1{
    width: 15vw;
    position: fixed;
    top:2vw;
    left: 2vw;
  }
  .link_ima{
    position: fixed;
    bottom:2vw;
    left: 2vw;
    background-color:#41A237;
    width: 20vw;
    height: 20vw;
    margin-bottom: 0px;
    font-size: 1rem;
    color: #fff;
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    border-radius: 5px;
  }
  .link_mirai{
    position: fixed;
    bottom:2vw;
    right: 2vw;
    background-color: #0065A6;
    width: 20vw;
    height: 20vw;
    margin-bottom: 0px;
    font-size: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    border-radius: 5px;
  }
  .link_ima span,
  .link_mirai span{
    font-size: 1rem;
  }
  .link_ima:hover,
  .link_mirai:hover{
    color: #ccc;
  }
  /*
  * Tree
  */
  .tree {
    bottom: 6vh;
  }
  .size_xs{
    width: 30vw;
  }
  .size_s{
    width: 45vw;
  }
  .size_m{
    width: 75vw;
  }
  .size_xl{
    width: 98vw;
  }
  .open_btn{
    top: 4vw;
    left: auto;
    right: 4vw;
    font-size: 0.8rem;
    padding: 5px 20px;
  }
  /*-----------------
  * modal
  ------------------*/
  .modal{
    width: 90vw;
    padding: 40px 5vw;
  }
  .modal h2{
    font-size: 0.8rem;
    display: block;
  }
  .modal div p{
    font-size: 0.5rem;
  }
  .modal h3{
    font-size: 0.6rem;
  }
  .modal textarea{
    font-size: 0.6rem;
  }
  .modal button{
    margin: 1vw auto 0;
  }
  .modal .disableBtn,
  .modal .submitBtn{
    font-size: 0.7rem;
    padding: 5px 15px;
  }
  .modal .notes{
    margin-top: 2vw;
    font-size: 0.4rem;
  }
  .modal .thanks h2{
    color: #9f000c;
    margin: 10px 0;
  }
  .modal .thanks p{
    margin-bottom: 10px;
  }
  /*-----------------
  * Bubble
  ------------------*/
  /* SPは最新5件を表示 */
  .msg_5,
  .msg_6,
  .msg_7,
  .msg_8,
  .msg_9{
    display: none;
  }
  .bubble {
    width: 35vw;
    height: 35vw;
  }
  .bubble span{
    font-size: 10px;
    line-height: 1rem;
    transform:scale(0.7);
  }
  .msg_0 {
    top: 50%;
    left: 7%;
  }
  .msg_1 {
    top: 12%;
    left: 56%;
  }
  .msg_2 {
    width: 34vw;
    height: 34vw;
    top: 18%;
    left: 13%;
  }
  .msg_3 {
    top: 34%;
    left: 42%;
  }
  .msg_4 {
    width: 34vw;
    height: 34vw;
    top: 56%;
    left: 64%;
  }

  /* debug */
  /* .bubble {display: none;}  */

  /* END SP対応 */
}

/*-----------------
* 会場モニター
------------------*/
@media screen and (min-width: 3000px) {
  .link_ima,
  .link_mirai{
    display: none;
  }
  .nav{
    width: 8vw;
  }
  .size_xs{
    width: 20vw;
  }
  .size_s{
    width: 35vw;
  }
  .size_m{
    width: 45vw;
  }
  .size_xl{
    width: 70vw;
  }
  .bubble {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 15vw;
    height: 15vw;
  }
  .msg_0 {
    width: 14vw;
    height: 14vw;
  }
  .msg_2 {
    width: 12vw;
    height: 12vw;
  }
  .msg_4 {
    width: 14vw;
    height: 14vw;
  }
  .msg_5 {
    width: 17vw;
    height: 17vw;
  }
  .msg_7 {
    width: 12vw;
    height: 12vw;
  }
}

